import { Clickable, Icon, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import SearchSelectionModal from '@Components/SearchForm/SearchSelectionModal';
import { SortOptionModal } from '@Components/SearchForm/SortOptionsModal';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

type SortButtonProps = {
  onClose?: () => void;
};

export const SortButton: React.FC<SortButtonProps> = ({ onClose }) => {
  const mapSearch = useFeatureFlag('MapSearch');
  const { t } = useTranslation();

  return (
    <SearchSelectionModal
      label="sort"
      eventAction="sort"
      onClose={onClose}
      excludeResetButton
      childButton={
        <Clickable
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            width: '100%',
            ...(mapSearch && {
              borderRight: 'solid',
              borderWidth: '1px',
              borderColor: 'strokeDarkneutral',
            }),
          }}
        >
          <Icon
            name="Actions/Sort"
            size="16"
            sx={{
              marginRight: '3xs',
            }}
          />
          <Label
            variant="mediumbold"
            sx={{ color: 'textPrimarydark' }}
          >
            {t('filters.sort')}
          </Label>
        </Clickable>
      }
      childContent={<SortOptionModal />}
    />
  );
};
