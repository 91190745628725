import loadable from '@loadable/component';
import { useInView } from '@loveholidays/design-system';
import React, { useMemo, useState } from 'react';

import {
  PromotionalCard as PromotionalCardType,
  DynamicPackageResultOrPromotionalCard,
} from '@AuroraTypes';
import { LoadingCard } from '@Pages/search-results/card/LoadingCard';
import { SearchResultCard } from '@Pages/search-results/card/SearchResultCard';
import { usePandaPageUrl } from '@Pages/search-results/usePandaPageUrl';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { useProductImpressionTracking } from '@Tracking';

const PromotionalCard = loadable(() => import('@Components/PromotionalCard/PromotionalCard'), {
  resolveComponent: (components) => components.PromotionalCard,
});

export interface SearchResultGroupProps {
  groupIndex: number;
  results: DynamicPackageResultOrPromotionalCard[];
  lazy?: boolean;
}

export const resultsPerGroup = 5;

const isPromotionalCard = (
  item: DynamicPackageResultOrPromotionalCard,
): item is PromotionalCardType => !('hotel' in item);

export const SearchResultGroup: React.FC<SearchResultGroupProps> = ({
  groupIndex,
  results,
  lazy,
}) => {
  const [inView, setInView] = useState(false);
  const inViewRef = useInView(
    (isInView) => {
      if (isInView) {
        setInView(true);
      }
    },
    {
      disabled: !lazy,
      triggerOnlyOnce: true,
      threshold: 0,
      rootMargin: '600px', // roughly SRP card height on mobile
    },
  );

  const [departureAirports, filters, nights, rooms, isFamilySearch] = useSearchSelectionStore(
    (state) => [
      state.departureAirports,
      state.filters,
      state.nights,
      state.rooms,
      state.isFamilySearch(),
    ],
  );
  const { getPandaUrlForOffer } = usePandaPageUrl();

  const items = results.slice(groupIndex * 5, (groupIndex + 1) * 5);
  const offers = results.filter((r) => !isPromotionalCard(r));
  const itemsJson = JSON.stringify(items);

  return useMemo(
    () => (
      <div
        data-id={`group-${groupIndex}`}
        ref={inViewRef}
      >
        {lazy && !inView
          ? items.map((result, i) => (
              <LoadingCard
                data-id="srp-loading-card"
                key={i}
                sx={{
                  marginBottom: 'l',
                }}
              />
            ))
          : items.map((result, index) => {
              if (isPromotionalCard(result)) {
                return (
                  <PromotionalCard
                    key={index}
                    {...result}
                    sx={{
                      marginBottom: 'l',
                    }}
                  />
                );
              }

              const position = offers.findIndex((offer) => offer === result) + 1;
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const ref = useProductImpressionTracking({
                masterId: result.hotel.accommodation.id,
                name: result.hotel.accommodation.name,
                nights: result.hotel.nights,
                inboundFlight: result.flights.inbound,
                outboundFlight: result.flights.outbound,
                list: 'SearchResults',
                position,
                price: result.pricing.totalPaidToUs,
                priceIncludingPaidAtHotel: result.pricing.totalIncludingPaidAtHotel,
                xref: result.pricing.referral?.xref,
                uuid: result.pricing.referral?.uuid,
                hasImages: !!result.hotel.accommodation.images.count,
                refundablePackage: !!result.badges.some(({ type }) => type === 'FREE_CANCELLATION'),
                isLuggageIncluded: result.flights.luggage.isIncluded,
              });

              return (
                <SearchResultCard
                  key={result.hotel.accommodation.id}
                  ref={ref}
                  accommodation={result.hotel.accommodation}
                  isFamily={isFamilySearch}
                  offer={result}
                  pandaUrl={getPandaUrlForOffer(result, {
                    filters,
                    departureAirports,
                    nights,
                    rooms,
                    source: 'srp',
                  })}
                  lazyImage={position !== 1}
                  position={position}
                  sx={{
                    marginBottom: 'l',
                  }}
                />
              );
            })}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsJson, inView],
  );
};
